/* You can add global styles to this file, and also import other style files */
$primary-color: #1a2f5a;


@font-face {
  font-family: 'Monrad Regular';
  src: url('assets/fonts/Monrad Grotesk-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monrad Black';
  src: url('assets/fonts/Monrad Grotesk-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Monrad Black Italic';
  src: url('assets/fonts/Monrad Grotesk-BlackIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Monrad Bold';
  src: url('assets/fonts/Monrad Grotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Monrad Bold Italic';
  src: url('assets/fonts/Monrad Grotesk-BoldIt.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Monrad Semibold';
  src: url('assets/fonts/Monrad Grotesk-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Monrad Semibold Italic';
  src: url('assets/fonts/Monrad Grotesk-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Monrad Regular';
  src: url('assets/fonts/Monrad Grotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Monrad Light';
  src: url('assets/fonts/Monrad Grotesk-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Monrad Light Italic';
  src: url('assets/fonts/Monrad Grotesk-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Monrad ExtraLight';
  src: url('assets/fonts/Monrad Grotesk-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Monrad ExtraLight Italic';
  src: url('assets/fonts/Monrad Grotesk-ExtraLightIt.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}



@font-face {
  font-family: 'Space Mono Regular';
  src: url('assets/fonts/SpaceMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


html, body {}
body { margin: 0; font-family: 'Monrad Regular', "Helvetica Neue", sans-serif; }
body {
  overflow: visible !important;
}
